import { useLocation } from "@reach/router";
import { message } from "antd";
import { navigate } from "gatsby";
import { get } from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import SelectLanguageModal from "../../../components/setting/components/SelectLanguageModal";
import { logoutIconImg, newLogoImg } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { clearModal, triggerModal } from "../../../redux/actions/app-actions";
import {
  loginSuccessful,
  logoutSuccessful
} from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import { mainColor } from "../../../styles/config";

// markup
const NavHeader = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [selectVisible, setSelectVisible] = useState(false);
  const currentPath =
    location.pathname[location.pathname.length - 1] === "/" &&
    location.pathname.length > 1
      ? location.pathname.slice(0, -1)
      : location.pathname;
  let user = get(props, "user.user");
  return (
    <React.Fragment>
      <div className="flex justify-between  px-2 mb-3">
        {/* <div></div> */}
        <div
          className="py-2 flex cursor-pointer "
          // style={{ width: "50%" }}
          onClick={() => {
            window.location.assign(routes.myits.to());
          }}
        >
          <span>
            <img src={newLogoImg} style={{ width: "21px", height: "23px" }} />
          </span>
          <span className="pl-2">IndoITS</span>
        </div>
        <div
          className="py-2 flex "
          //   style={{ color: mainColor }}
        >
          {get(user, "username") || ""}
        </div>

        <div className=" flex py-2">
          <span
            className=" pr-4 flex cursor-pointer underline"
            style={{ color: mainColor }}
            onClick={() => {
              setSelectVisible(true);
            }}
          >
            {t("language", sourceKey.profile)}
          </span>
          <span
            className="cursor-pointer"
            onClick={() => {
              // props.logoutSuccessful();
              props.logoutSuccessful();
              props.clearModal();
              // message.success(t("logout"));
              // if (typeof window !== `undefined`) {
              //   navigate(routes.home.to());
              // }
              navigate(routes.myits.to());
            }}
          >
            <img
              src={logoutIconImg}
              style={{ width: "21px", height: "23px" }}
            />
          </span>
        </div>
      </div>
      <div className="">{props.children}</div>

      <SelectLanguageModal
        visible={selectVisible}
        onClose={() => {
          setSelectVisible(false);
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  logoutSuccessful,
  clearModal,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(NavHeader);
